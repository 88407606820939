var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var rsa=function(a,b){return new $CLJS.ie(null,function(){a:for(var c=a,d=b;;){d=$CLJS.y(d);var e;if(e=d)e=$CLJS.z(d),e=c.h?c.h(e):c.call(null,e);if($CLJS.n(e))d=$CLJS.Hc(d);else break a}return d},null,null)},ssa=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rt(a,b),rsa(a,b)],null)},Q4=function(a){var b=$CLJS.wk.l;var c=$CLJS.O(a);var d=$CLJS.J.g(c,$CLJS.J4);c=$CLJS.J.g(c,$CLJS.K4);if($CLJS.n($CLJS.n(d)?$CLJS.E.g(c,"currency"):d)){c=$CLJS.E4.h;d=$CLJS.mh.h(d);var e=$CLJS.q($CLJS.B4);
d=e.h?e.h(d):e.call(null,d);c=c.call($CLJS.E4,d);c=new $CLJS.h(null,2,[$CLJS.I4,c,$CLJS.L4,c],null)}else c=new $CLJS.h(null,1,[$CLJS.L4,2],null);b=b.call($CLJS.wk,$CLJS.H([c,a]));return $CLJS.n($CLJS.M4.h(a))?$CLJS.R.l($CLJS.Xj.g(b,$CLJS.M4),$CLJS.L4,$CLJS.M4.h(a),$CLJS.H([$CLJS.I4,$CLJS.M4.h(a)])):b},R4=function(a,b){if(null!=a&&null!=a.Oe)a=a.Oe(a,b);else{var c=R4[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=R4._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("NumberFormatter.format-number-basic",
a);}return a},tsa=function(a,b){var c=$CLJS.Ly(a.formatToParts(b),$CLJS.H([new $CLJS.h(null,1,[$CLJS.ii,!0],null)]));a=$CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.Ri.h(d),"exponentMinusSign")},c))?c:function(){var d=ssa(function(f){return $CLJS.dk.g($CLJS.Ri.h(f),"exponentInteger")},c),e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.bf.l(e,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Ri,"exponentPlusSign",$CLJS.ej,"+"],null)],null),$CLJS.H([d]))}();return $CLJS.Ne.g($CLJS.p,
$CLJS.cf.g(function(d){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.Ri);e=$CLJS.J.g(e,$CLJS.ej);switch(d){case "exponentSeparator":return"e";default:return e}},a))},usa=function(a,b){switch($CLJS.K4.h(b)){case "scientific":return function(c){return tsa(a,c)};default:return function(c){return a.format(c)}}},S4=function(a,b,c,d,e){this.options=a;this.Bg=b;this.Eg=c;this.de=d;this.li=e;this.C=393216;this.I=0},vsa=function(a,b){return $CLJS.Ne.g($CLJS.p,function(){return function e(d){return new $CLJS.ie(null,
function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.hd(k,v),A=$CLJS.O(x);x=$CLJS.J.g(A,$CLJS.Ri);A=$CLJS.J.g(A,$CLJS.ej);var C=new $CLJS.Rg(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);C=C.h?C.h(x):C.call(null,x);$CLJS.n(C)&&(x=$CLJS.E.g(x,"integer")?b:A,m.add(x));v+=1}else return!0}()?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}var t=$CLJS.z(f);t=$CLJS.O(t);
var u=$CLJS.J.g(t,$CLJS.Ri);t=$CLJS.J.g(t,$CLJS.ej);if($CLJS.n(function(){var v=new $CLJS.Rg(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);return v.h?v.h(u):v.call(null,u)}()))return $CLJS.ae($CLJS.E.g(u,"integer")?b:t,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}($CLJS.Ly(a.Bg.formatToParts(1),$CLJS.H([$CLJS.ii,!0])))}())},T4=function(a){var b=$CLJS.E.g($CLJS.K4.h(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.yk($CLJS.Jz($CLJS.Sa,$CLJS.zg([$CLJS.osa,
$CLJS.gsa,$CLJS.jsa,$CLJS.lsa,$CLJS.qsa,$CLJS.J4,$CLJS.ksa,$CLJS.msa,$CLJS.hsa,$CLJS.isa],[$CLJS.L4.g(a,b),$CLJS.O4.h(a),$CLJS.N4.h(a),$CLJS.nsa.h(a),$CLJS.E.g($CLJS.K4.h(a),"scientific")?null:$CLJS.K4.g(a,"decimal"),$CLJS.J4.h(a),!0,$CLJS.I4.g(a,b),$CLJS.E.g($CLJS.K4.h(a),"scientific")?"scientific":null,$CLJS.P4.h(a)]))));var c=$CLJS.O4.h(a);var d=$CLJS.J4.h(a);c=$CLJS.n(d)?null==c||$CLJS.E.g(c,"symbol"):d;d=usa(b,a);return new S4(a,b,c,d,$CLJS.N)},U4=function(a,b){return T4(Q4(b)).Oe(null,a)},W4=
function(a,b){var c=$CLJS.Xj.l(b,$CLJS.AA,$CLJS.H([$CLJS.K4])),d=$CLJS.yz(a);if(0===a)return"0";if(1E3>d)return $CLJS.V4.g?$CLJS.V4.g(a,c):$CLJS.V4.call(null,a,c);b=$CLJS.z($CLJS.ff(function(f){return d>=$CLJS.z(f)},wsa));var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return[$CLJS.p.h(function(){var f=a/e,k=$CLJS.wk.l($CLJS.H([c,new $CLJS.h(null,2,[$CLJS.I4,1,$CLJS.L4,1],null)]));return $CLJS.V4.g?$CLJS.V4.g(f,k):$CLJS.V4.call(null,f,k)}()),$CLJS.p.h(b)].join("")},xsa=function(a,b){var c=Q4(b);if($CLJS.n(X4.h(c)))b=
X4.h(c);else if($CLJS.Ra($CLJS.M4.h(c))&&$CLJS.Ra($CLJS.I4.h(c))&&$CLJS.dk.g($CLJS.K4.h(c),"currency")&&$CLJS.yz(a)<($CLJS.E.g($CLJS.K4.h(c),"percent")?.01:1)){b=$CLJS.R.j;var d=$CLJS.Xj.g(c,$CLJS.L4);c=$CLJS.P4.g(c,0);b=T4(b.call($CLJS.R,d,$CLJS.N4,2>c?2:c))}else b=T4(c);return R4(b,a)};S4.prototype.P=function(a,b){return new S4(this.options,this.Bg,this.Eg,this.de,b)};S4.prototype.O=function(){return this.li};
S4.prototype.Oe=function(a,b){a=this.de.h?this.de.h(b):this.de.call(null,b);var c=$CLJS.psa.h(this.options);$CLJS.n($CLJS.n(c)?$CLJS.dk.g(c,".,"):c)&&(b=$CLJS.z(c),c=$CLJS.dd(c),a=$CLJS.Gz(a,/[\.,]/,new $CLJS.h(null,2,[",",$CLJS.n(c)?c:"",".",b],null)));$CLJS.n(this.Eg)&&(b=$CLJS.J4.h(this.options),c=$CLJS.C4(b),a=$CLJS.Gz($CLJS.Gz(a,[$CLJS.Xg(b)," "].join(""),c),$CLJS.Xg(b),c));return a};
var X4=new $CLJS.M(null,"number-formatter","number-formatter",929788393),Y4=new $CLJS.M(null,"scale","scale",-230427353),Z4=new $CLJS.M(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),ysa=new $CLJS.M(null,"scientific","scientific",316285837);var $4=new $CLJS.h(null,1,[$CLJS.O4,"symbol"],null),zsa=$CLJS.yk($4),wsa=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[1E12,"T"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E9,"B"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E6,"M"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E3,"k"],null)],null),a5=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Fk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,k){f=$CLJS.O(k);return $CLJS.J.g(f,$CLJS.K4)},e,a,b,c,d)}();a5.m(null,$CLJS.Oh,function(a,b){return W4(a,b)});a5.m(null,"percent",function(a,b){return[$CLJS.p.h(W4(100*a,b)),"%"].join("")});a5.m(null,"currency",function(a,b){b=$CLJS.wk.l($CLJS.H([b,$4]));var c=T4(b);return 1E3>$CLJS.yz(a)?c.Oe(null,a):vsa(c,W4(a,b))});a5.m(null,"scientific",function(a,b){return U4(a,$CLJS.wk.l($CLJS.H([b,new $CLJS.h(null,2,[$CLJS.L4,1,$CLJS.I4,1],null)])))});
$CLJS.V4=function V4(a,b){b=$CLJS.fA(b);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.AA);var e=$CLJS.J.g(d,Z4),f=$CLJS.J.g(d,$CLJS.K4),k=$CLJS.J.g(d,Y4);return $CLJS.n($CLJS.n(k)?!isNaN(k):k)?(b=k*a,f=$CLJS.Xj.g(d,Y4),V4.g?V4.g(b,f):V4.call(null,b,f)):$CLJS.n(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.h(function(){var l=-a,m=$CLJS.R.j(d,Z4,!1);return V4.g?V4.g(l,m):V4.call(null,l,m)}()),")"].join(""):$CLJS.n(b)?a5.g(a,Q4($CLJS.Xj.g(d,$CLJS.AA))):$CLJS.E.g($CLJS.mh.h(f),ysa)?U4(a,d):xsa(a,d)};
module.exports={compact_currency_options_js:zsa,format_number:$CLJS.V4};