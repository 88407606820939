var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var vG,wG,yG,zG,xha,yha,zha,AG,xG;$CLJS.tG=function(a,b){return $CLJS.cc($CLJS.cb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.Yd.g($CLJS.J.j(c,e,$CLJS.xf),d))},$CLJS.ac($CLJS.N),b))};$CLJS.uG=function(a,b){return $CLJS.cb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Oc(d):null},null,b)};
vG=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.Dz(b,$CLJS.ji)?$CLJS.eG:$CLJS.Dz(b,$CLJS.Nj)?$CLJS.aG:$CLJS.Dz(b,$CLJS.dj)?$CLJS.cD:null;return $CLJS.n(b)?$CLJS.rE(b,a):!0};
wG=function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);a=$CLJS.tG(function(d){return $CLJS.Dz($CLJS.OD(d),$CLJS.aC)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.dk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.OD($CLJS.z(a));return $CLJS.Me(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(vG(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
yG=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.us,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xG],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.Fs,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.ej);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(wG(b))].join("")}],null),$CLJS.Oe(wG)],null)],null)};
zG=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.us,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kE],null)],null)],null)],null)};
xha=function(a){return $CLJS.Md($CLJS.uE,$CLJS.cf.g(function(b){var c=$CLJS.OD(b),d=$CLJS.Dz(c,$CLJS.aE);b=d?$CLJS.rE($CLJS.qF,b):d;return $CLJS.n(b)?$CLJS.oj:c},a))};yha=function(a){a=$CLJS.uG(function(b){return!$CLJS.Dz(b,$CLJS.aC)},$CLJS.cf.g($CLJS.OD,a));return $CLJS.Dz(a,$CLJS.aE)?$CLJS.tj:a};
zha=function(a,b){return $CLJS.n($CLJS.Me(function(c){return $CLJS.Dz($CLJS.OD(c),$CLJS.aC)},b))?yha(b):$CLJS.E.g(a,$CLJS.$r)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Le(function(c){return $CLJS.Dz($CLJS.OD(c),$CLJS.ji)},b)||$CLJS.Le(function(c){return $CLJS.Dz($CLJS.OD(c),$CLJS.dj)},b))?$CLJS.aC:xha(b)};AG=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.BG=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);xG=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,xG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=$CLJS.OD(a);return $CLJS.Le(function(d){return vG(d,c)},b)}],null)],null));
$CLJS.Y(AG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kE],null)],null)],null));
var Aha=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.us,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.$r],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)],
null);$CLJS.LF.g($CLJS.Jq,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,yG($CLJS.Jq),zG($CLJS.Jq)],null));$CLJS.LF.g($CLJS.$r,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.er,yG($CLJS.$r),Aha,zG($CLJS.$r)],null));$CLJS.BE($CLJS.Kq,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,AG],null)]));$CLJS.BE($CLJS.GF,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,AG],null)]));
for(var CG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Jq,$CLJS.$r,$CLJS.Kq],null)),DG=null,EG=0,FG=0;;)if(FG<EG){var Bha=DG.X(null,FG);$CLJS.sE(Bha,$CLJS.BG);FG+=1}else{var GG=$CLJS.y(CG);if(GG){var HG=GG;if($CLJS.vd(HG)){var IG=$CLJS.ic(HG),Cha=$CLJS.jc(HG),Dha=IG,Eha=$CLJS.D(IG);CG=Cha;DG=Dha;EG=Eha}else{var Fha=$CLJS.z(HG);$CLJS.sE(Fha,$CLJS.BG);CG=$CLJS.B(HG);DG=null;EG=0}FG=0}else break}
$CLJS.ND.m(null,$CLJS.BG,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);$CLJS.z(b);b=$CLJS.B(b);return zha(a,b)});$CLJS.zE($CLJS.ME,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kE],null)],null)]));$CLJS.sE($CLJS.ME,$CLJS.eE);
for(var JG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,$CLJS.DE,$CLJS.$E],null)),KG=null,LG=0,MG=0;;)if(MG<LG){var Gha=KG.X(null,MG);$CLJS.zE(Gha,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kE],null)],null)]));MG+=1}else{var NG=$CLJS.y(JG);if(NG){var OG=NG;if($CLJS.vd(OG)){var PG=$CLJS.ic(OG),Hha=$CLJS.jc(OG),Iha=PG,Jha=$CLJS.D(PG);JG=Hha;KG=Iha;LG=Jha}else{var Kha=$CLJS.z(OG);$CLJS.zE(Kha,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kE],null)],null)]));JG=$CLJS.B(OG);KG=null;LG=0}MG=0}else break}
for(var QG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HE,$CLJS.kF,$CLJS.rF],null)),RG=null,SG=0,TG=0;;)if(TG<SG){var Lha=RG.X(null,TG);$CLJS.zE(Lha,$CLJS.H([$CLJS.$r,$CLJS.Yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kE],null)],null)]));TG+=1}else{var UG=$CLJS.y(QG);if(UG){var VG=UG;if($CLJS.vd(VG)){var WG=$CLJS.ic(VG),Mha=$CLJS.jc(VG),Nha=WG,Oha=$CLJS.D(WG);QG=Mha;RG=Nha;SG=Oha}else{var Pha=$CLJS.z(VG);$CLJS.zE(Pha,$CLJS.H([$CLJS.$r,$CLJS.Yi,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kE],null)],null)]));QG=$CLJS.B(VG);RG=null;SG=0}TG=0}else break}$CLJS.zE($CLJS.JE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kE],null)],null)]));
$CLJS.ND.m(null,$CLJS.JE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.Dz($CLJS.OD(b),$CLJS.Yi)&&$CLJS.Dz($CLJS.OD(a),$CLJS.Yi)?$CLJS.Yi:$CLJS.LC});