var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var L3,N3,P3,Q3,R3,S3,T3,Fra;L3=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=L3[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=L3._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.metric",a);}return a};$CLJS.M3=function(a,b){return L3($CLJS.$Z(a),b)};
N3=function(a,b,c){var d=$CLJS.EV(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.bF);d=$CLJS.uG($CLJS.ek.j($CLJS.Tg([c]),$CLJS.zD,$CLJS.dd),d);if(!$CLJS.n(d))throw $CLJS.Uh($CLJS.fD("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.tr,c,$CLJS.IE,a,$CLJS.FK,b],null));return d};$CLJS.O3=function(a){return $CLJS.D1($CLJS.yF,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};P3=new $CLJS.M("metabase.lib.aggregation","no-semantic-type","metabase.lib.aggregation/no-semantic-type",-789440092);
Q3=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);R3=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);S3=new $CLJS.M("metabase.lib.aggregation","quantity-aggregation","metabase.lib.aggregation/quantity-aggregation",411632918);T3=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);
Fra=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.M_.m(null,$CLJS.bF,function(a,b){var c=$CLJS.Be($CLJS.bF.h($CLJS.EV(a,b)));return $CLJS.n(c)?$CLJS.G1($CLJS.LD("and"),function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u);v=$CLJS.i_.v(a,b,v,$CLJS.j_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.i_.v(a,
b,t,$CLJS.j_),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):null});$CLJS.P_.m(null,$CLJS.bF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.tA);e=$CLJS.J.g(e,$CLJS.oi);c=$CLJS.I(c,2,null);c=N3(a,b,c);return $CLJS.wk.l($CLJS.H([$CLJS.g_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.OJ,$CLJS.lK,$CLJS.A0,$CLJS.zD.h($CLJS.dd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.tA,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.oi,e],null):null]))});
$CLJS.J_.m(null,$CLJS.bF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.i_.v(a,b,N3(a,b,c),d)});$CLJS.sE(R3,T3);
for(var U3=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.nF,$CLJS.vF],null)),V3=null,W3=0,X3=0;;)if(X3<W3){var Gra=V3.X(null,X3);$CLJS.sE(Gra,R3);X3+=1}else{var Y3=$CLJS.y(U3);if(Y3){var Z3=Y3;if($CLJS.vd(Z3)){var $3=$CLJS.ic(Z3),Hra=$CLJS.jc(Z3),Ira=$3,Jra=$CLJS.D($3);U3=Hra;V3=Ira;W3=Jra}else{var Kra=$CLJS.z(Z3);$CLJS.sE(Kra,R3);U3=$CLJS.B(Z3);V3=null;W3=0}X3=0}else break}
$CLJS.J_.m(null,R3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.i_.v(a,b,c,d),e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.fD("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.fD("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.LD("Count");case "cum-count":return $CLJS.LD("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.K_.m(null,R3,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";case "count-where":return"count_where";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.P_.m(null,S3,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.nH($CLJS.P_,T3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.sj,$CLJS.SB)});
$CLJS.sE(S3,T3);$CLJS.sE(R3,S3);$CLJS.sE($CLJS.RE,S3);$CLJS.J_.m(null,$CLJS.VE,function(){return $CLJS.LD("Case")});$CLJS.K_.m(null,$CLJS.VE,function(){return"case"});$CLJS.sE(Q3,T3);
for(var a4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.gF,$CLJS.HF,$CLJS.RE,$CLJS.Bj,$CLJS.zF,$CLJS.si,$CLJS.AF,$CLJS.yF,$CLJS.SE],null)),b4=null,c4=0,d4=0;;)if(d4<c4){var Lra=b4.X(null,d4);$CLJS.sE(Lra,Q3);d4+=1}else{var e4=$CLJS.y(a4);if(e4){var f4=e4;if($CLJS.vd(f4)){var g4=$CLJS.ic(f4),Mra=$CLJS.jc(f4),Nra=g4,Ora=$CLJS.D(g4);a4=Mra;b4=Nra;c4=Ora}else{var Pra=$CLJS.z(f4);$CLJS.sE(Pra,Q3);a4=$CLJS.B(f4);b4=null;c4=0}d4=0}else break}
$CLJS.K_.m(null,Q3,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.J_.m(null,Q3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.i_.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "avg":return $CLJS.fD("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.fD("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.fD("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.fD("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.fD("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.fD("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.fD("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.fD("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.fD("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.J_.m(null,$CLJS.YE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.fD("{0}th percentile of {1}",$CLJS.H([c,$CLJS.i_.v(a,b,e,d)]))});$CLJS.K_.m(null,$CLJS.YE,function(){return"percentile"});
$CLJS.sE(P3,T3);for(var h4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,$CLJS.SE],null)),i4=null,j4=0,k4=0;;)if(k4<j4){var Qra=i4.X(null,k4);$CLJS.sE(Qra,P3);k4+=1}else{var l4=$CLJS.y(h4);if(l4){var m4=l4;if($CLJS.vd(m4)){var n4=$CLJS.ic(m4),Rra=$CLJS.jc(m4),Sra=n4,Tra=$CLJS.D(n4);h4=Rra;i4=Sra;j4=Tra}else{var Ura=$CLJS.z(m4);$CLJS.sE(Ura,P3);h4=$CLJS.B(m4);i4=null;j4=0}k4=0}else break}
$CLJS.P_.m(null,P3,function(a,b,c){var d=$CLJS.Xj.g,e=$CLJS.nH($CLJS.P_,T3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.Xj,a,$CLJS.sj)});$CLJS.J_.m(null,$CLJS.FF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.fD("Sum of {0} matching condition",$CLJS.H([$CLJS.i_.v(a,b,e,d)]))});
$CLJS.K_.m(null,$CLJS.FF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.L_.j(a,b,d))].join("")});$CLJS.sE($CLJS.FF,T3);$CLJS.J_.m(null,$CLJS.tF,function(){return $CLJS.LD("Share of rows matching condition")});$CLJS.K_.m(null,$CLJS.tF,function(){return"share"});$CLJS.P_.m(null,$CLJS.tF,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.nH($CLJS.P_,T3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.sj,$CLJS.jC)});
$CLJS.sE($CLJS.tF,T3);$CLJS.J_.m(null,$CLJS.vF,function(){return $CLJS.LD("Count of rows matching condition")});$CLJS.P_.m(null,T3,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.wk.l;d=$CLJS.n(d)?$CLJS.Cl($CLJS.g_.j(a,b,d),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cK,$CLJS.sj],null)):null;var f=$CLJS.nH($CLJS.P_,$CLJS.Oh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.wk,$CLJS.H([d,a]))});
$CLJS.Vra=function(){function a(d){return $CLJS.D1($CLJS.Rw,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.D1($CLJS.Rw,$CLJS.xf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.nV.m(null,$CLJS.bF,function(a){return a});
$CLJS.o4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.XA(f),$CLJS.RJ))f=$CLJS.pV(f);else return $CLJS.I1(d,e,$CLJS.bF,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.p4=function(){function a(d,e){return $CLJS.Be($CLJS.bF.h($CLJS.EV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.q4=function(){function a(d,e){var f=$CLJS.Be($CLJS.bF.h($CLJS.EV(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(k){var l=$CLJS.g_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.tA.h(l);return m.call($CLJS.R,$CLJS.C1(l,$CLJS.oi,$CLJS.n(t)?t:$CLJS.bj),$CLJS.OJ,$CLJS.lK,$CLJS.H([$CLJS.A0,$CLJS.zD.h($CLJS.dd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.J_.m(null,$CLJS.TF,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.PF);return $CLJS.mD.h(a.o?a.o():a.call(null))});$CLJS.R_.m(null,$CLJS.TF,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.fF);b=$CLJS.J.g(d,$CLJS.PF);c=$CLJS.J.g(d,$CLJS.OF);d=$CLJS.J.g(d,$CLJS.KY);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.H_,$CLJS.$z(a),$CLJS.H([$CLJS.F_,c]));return null!=d?$CLJS.R.j(a,$CLJS.q_,d):a});
$CLJS.Wra=function(){function a(d,e){var f=function(){var m=$CLJS.dz.h($CLJS.W0(d));return $CLJS.n(m)?m:$CLJS.Sg}(),k=$CLJS.EV(d,e),l=$CLJS.V_.j(d,e,k);return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.ek.j($CLJS.ef(function(m){m=$CLJS.RF.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.ul(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.OF),u=$CLJS.J.g(m,$CLJS.MF);if($CLJS.Ra(t))return m;if($CLJS.E.g(u,$CLJS.vr))return $CLJS.R.j(m,$CLJS.BV,l);t=$CLJS.Be($CLJS.v0(function(v){return $CLJS.AZ(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.BV,t):null}),$CLJS.cf.h(function(m){return $CLJS.R.j(m,$CLJS.fj,$CLJS.TF)})),$CLJS.UF))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Xra=function(){function a(d,e){return $CLJS.oV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fF.h(d),$CLJS.N,$CLJS.qV.h(e)],null))}function b(d){if($CLJS.Ra($CLJS.OF.h(d)))return $CLJS.oV(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fF.h(d),$CLJS.N],null));var e=$CLJS.fF.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.Uh(e,new $CLJS.h(null,1,[Fra,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();