var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var QI;$CLJS.OI=function(a){return $CLJS.n($CLJS.qB($CLJS.rd,$CLJS.qd,$CLJS.Yk)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.PI=function(a,b){return $CLJS.Me($CLJS.zd,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.ie(null,function(K,S,X,T){return function(){for(var da=C;;){var oa=$CLJS.y(da);if(oa){var Ma=oa,sb=$CLJS.z(Ma);if(oa=$CLJS.y(function(Qa,Xa,Ja,Ua,Ta,mb,dc,Kd){return function Rh(gd){return new $CLJS.ie(null,function(qz,Il,Un,yr,Q_,Nv){return function(){for(;;){var Ov=
$CLJS.y(gd);if(Ov){if($CLJS.vd(Ov)){var Pv=$CLJS.ic(Ov),rz=$CLJS.D(Pv),Ek=$CLJS.le(rz);a:for(var zr=0;;)if(zr<rz){var ht=$CLJS.hd(Pv,zr);ht=$CLJS.E.g(Nv,$CLJS.aE)||$CLJS.E.g(Un,$CLJS.aE)||$CLJS.CE(Nv,ht)&&$CLJS.CE(Un,ht);Ek.add(ht);zr+=1}else{Pv=!0;break a}return Pv?$CLJS.oe($CLJS.qe(Ek),Rh($CLJS.jc(Ov))):$CLJS.oe($CLJS.qe(Ek),null)}Ek=$CLJS.z(Ov);return $CLJS.ae($CLJS.E.g(Nv,$CLJS.aE)||$CLJS.E.g(Un,$CLJS.aE)||$CLJS.CE(Nv,Ek)&&$CLJS.CE(Un,Ek),Rh($CLJS.Hc(Ov)))}return null}}}(Qa,Xa,Ja,Ua,Ta,mb,dc,
Kd),null,null)}}(da,K,sb,Ma,oa,S,X,T)($CLJS.mE)))return $CLJS.bf.g(oa,G($CLJS.Hc(da)));da=$CLJS.Hc(da)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.OI($CLJS.OD(b)))))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.OI($CLJS.OD(a)))}())};
QI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.Ne.j($CLJS.yE,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);f=$CLJS.QD;
return $CLJS.n(f)?f:$CLJS.Le($CLJS.il,$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.PI($CLJS.J.g(k,m),$CLJS.J.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Hc(d);return c(e,d)};b.l=c;return b}()};$CLJS.RI=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.SI=new $CLJS.M("operator","filter","operator/filter",-1518842858);
$CLJS.TI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var UI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lr,$CLJS.er],null)),VI=null,WI=0,XI=0;;)if(XI<WI){var Kia=VI.X(null,XI);$CLJS.BE(Kia,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZD],null)],null)],null)],null)]));XI+=1}else{var YI=$CLJS.y(UI);if(YI){var ZI=YI;if($CLJS.vd(ZI)){var $I=$CLJS.ic(ZI),Lia=$CLJS.jc(ZI),
Mia=$I,Nia=$CLJS.D($I);UI=Lia;VI=Mia;WI=Nia}else{var Oia=$CLJS.z(ZI);$CLJS.BE(Oia,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZD],null)],null)],null)],null)]));UI=$CLJS.B(ZI);VI=null;WI=0}XI=0}else break}$CLJS.zE($CLJS.gr,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZD],null)]));
for(var aJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.CF],null)),bJ=null,cJ=0,dJ=0;;)if(dJ<cJ){var Pia=bJ.X(null,dJ);$CLJS.BE(Pia,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null)],null)],null)],null)]));dJ+=1}else{var eJ=$CLJS.y(aJ);if(eJ){var fJ=eJ;if($CLJS.vd(fJ)){var gJ=$CLJS.ic(fJ),Qia=$CLJS.jc(fJ),
Ria=gJ,Sia=$CLJS.D(gJ);aJ=Qia;bJ=Ria;cJ=Sia}else{var Tia=$CLJS.z(fJ);$CLJS.BE(Tia,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null)],null)],null)],null)]));aJ=$CLJS.B(fJ);bJ=null;cJ=0}dJ=0}else break}
for(var hJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Eq,$CLJS.Gq,$CLJS.Aq,$CLJS.Cq],null)),iJ=null,jJ=0,kJ=0;;)if(kJ<jJ){var Uia=iJ.X(null,kJ);$CLJS.xE.l(QI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Uia,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)]));kJ+=1}else{var lJ=$CLJS.y(hJ);if(lJ){var mJ=lJ;if($CLJS.vd(mJ)){var nJ=$CLJS.ic(mJ),Via=$CLJS.jc(mJ),Wia=nJ,Xia=$CLJS.D(nJ);hJ=Via;iJ=Wia;jJ=Xia}else{var Yia=
$CLJS.z(mJ);$CLJS.xE.l(QI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Yia,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)]));hJ=$CLJS.B(mJ);iJ=null;jJ=0}kJ=0}else break}
$CLJS.xE.l(QI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.OE,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)]));
$CLJS.xE.l(QI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.UE,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)]));
for(var oJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NE,$CLJS.ZE],null)),pJ=null,qJ=0,rJ=0;;)if(rJ<qJ){var Zia=pJ.X(null,rJ);$CLJS.zE(Zia,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)]));rJ+=1}else{var sJ=$CLJS.y(oJ);if(sJ){var tJ=sJ;if($CLJS.vd(tJ)){var uJ=$CLJS.ic(tJ),$ia=$CLJS.jc(tJ),aja=uJ,bja=$CLJS.D(uJ);oJ=$ia;pJ=aja;qJ=bja}else{var cja=$CLJS.z(tJ);$CLJS.zE(cja,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)]));oJ=$CLJS.B(tJ);
pJ=null;qJ=0}rJ=0}else break}
for(var vJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PE,$CLJS.WE],null)),wJ=null,xJ=0,yJ=0;;)if(yJ<xJ){var dja=wJ.X(null,yJ);$CLJS.zE(dja,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)]));yJ+=1}else{var zJ=$CLJS.y(vJ);if(zJ){var AJ=zJ;if($CLJS.vd(AJ)){var BJ=$CLJS.ic(AJ),eja=$CLJS.jc(AJ),fja=BJ,gja=$CLJS.D(BJ);vJ=eja;wJ=fja;xJ=gja}else{var hja=$CLJS.z(AJ);$CLJS.zE(hja,$CLJS.H([$CLJS.$r,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)]));vJ=$CLJS.B(AJ);
wJ=null;xJ=0}yJ=0}else break}
for(var CJ=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uF,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null),DJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dF,$CLJS.xF,$CLJS.lF,$CLJS.eF],null)),EJ=null,FJ=0,GJ=0;;)if(GJ<FJ){var HJ=EJ.X(null,GJ);$CLJS.LF.v(HJ,$CLJS.$r,$CLJS.mj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,HJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.xD,CJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.iE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iE],null)],null));GJ+=1}else{var IJ=$CLJS.y(DJ);if(IJ){var JJ=IJ;if($CLJS.vd(JJ)){var KJ=$CLJS.ic(JJ),ija=$CLJS.jc(JJ),jja=KJ,kja=$CLJS.D(KJ);DJ=ija;EJ=jja;FJ=kja}else{var LJ=$CLJS.z(JJ);$CLJS.LF.v(LJ,$CLJS.$r,$CLJS.mj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,LJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.xD,CJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iE],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.iE],null)],null));DJ=$CLJS.B(JJ);EJ=null;FJ=0}GJ=0}else break}
$CLJS.LF.v($CLJS.dB,$CLJS.$r,$CLJS.mj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.dB],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.xD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ik,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.Qu,$CLJS.Vw,$CLJS.TE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.lE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)],null));$CLJS.LF.v($CLJS.KE,$CLJS.$r,$CLJS.mj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.KE],null),$CLJS.xD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.iD,$CLJS.rD],null)],null));
$CLJS.Y($CLJS.RI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.SI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.br,$CLJS.Hq,$CLJS.CF,$CLJS.UE,$CLJS.OE,$CLJS.Eq,$CLJS.Aq,$CLJS.Gq,$CLJS.Cq,$CLJS.NE,$CLJS.ZE,$CLJS.PE,$CLJS.WE,$CLJS.lF,$CLJS.eF,$CLJS.dF,$CLJS.xF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TI,$CLJS.Fi],null)],null));