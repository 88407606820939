var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.cache.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Qoa,VZ,Roa,XZ,YZ,ZZ,v_,Uoa,Toa,Voa,Soa,B_,m_,D_,h_,Woa,o_;Qoa=function(a){return $CLJS.y(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};VZ=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Cd($CLJS.Yfa,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
Roa=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.vA,!0],null)),c=$CLJS.J.g(b,$CLJS.Xfa),d=$CLJS.J.g(b,$CLJS.vA);return $CLJS.y(a)?(b=$CLJS.vU(function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);x=VZ(x,$CLJS.Ra(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae(VZ(u,
$CLJS.Ra(d)),k($CLJS.Hc(l)))}return null}},null,null)}(Qoa($CLJS.aA(a)))}()),$CLJS.n(c)?$CLJS.vU($CLJS.We(c,b)):b):null};$CLJS.WZ=function(a){return $CLJS.eO.h($CLJS.z($CLJS.sK.h(a)))};XZ=function(a,b){if(null!=a&&null!=a.Ag)a=a.Ag(a,b);else{var c=XZ[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=XZ._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.table",a);}return a};
YZ=function(a,b){if(null!=a&&null!=a.rg)a=a.rg(a,b);else{var c=YZ[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=YZ._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.card",a);}return a};ZZ=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=ZZ[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=ZZ._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.fields",a);}return a};
$CLJS.$Z=function(a){return $CLJS.tY(a)?a:$CLJS.gD.h(a)};$CLJS.a_=function(a,b){return XZ($CLJS.$Z(a),b)};$CLJS.b_=function(a,b){return ZZ($CLJS.$Z(a),b)};$CLJS.c_=function(a,b){return YZ($CLJS.$Z(a),b)};
$CLJS.e_=function(a,b,c,d,e){var f=$CLJS.O(e);e=$CLJS.J.j(f,$CLJS.bZ,$CLJS.d_);f=$CLJS.J.g(f,$CLJS.aZ);if($CLJS.n($CLJS.n(f)?f:$CLJS.Va(b)||$CLJS.sd(b))&&($CLJS.n(b.__mbcache)||(b.__mbcache=e.o?e.o():e.call(null)),b=b.__mbcache,$CLJS.n(b))){f=e.j?e.j(b,a,c):e.call(null,b,a,c);if($CLJS.n(f))return f;d=d.h?d.h(c):d.call(null,c);e.v?e.v(b,a,c,d):e.call(null,b,a,c,d);return d}return d.h?d.h(c):d.call(null,c)};
$CLJS.f_=function(a,b,c){return $CLJS.e_(a,b,b,c,new $CLJS.h(null,2,[$CLJS.bZ,$CLJS.d_,$CLJS.aZ,!1],null))};
v_=function(a,b,c){var d=$CLJS.g_.j(a,b,c);return $CLJS.wk.l($CLJS.H([$CLJS.Cl(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,$CLJS.mD,$CLJS.sj],null)),function(){var e=$CLJS.OV.h(c);return $CLJS.n(e)?new $CLJS.h(null,2,[$CLJS.mD,e,h_,!0],null):null}(),function(){var e=$CLJS.i_.v(a,b,c,$CLJS.j_);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.k_,e],null):null}(),function(){var e=$CLJS.zz($CLJS.oi,$CLJS.tA)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.oi,e],null):null}(),function(){var e=$CLJS.bK.h(d);return $CLJS.n(e)?
(e=$CLJS.Ad(e)?$CLJS.a_(a,e):"string"===typeof e?$CLJS.c_(a,$CLJS.FV(e)):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Ij,$CLJS.l_.j(a,b,e)],null):null):null}(),function(){var e=$CLJS.OJ.h(d);return $CLJS.n(e)?new $CLJS.h(null,6,[m_,$CLJS.E.g(e,$CLJS.vY),$CLJS.n_,$CLJS.E.g(e,$CLJS.GY),o_,$CLJS.E.g(e,$CLJS.DY),$CLJS.p_,$CLJS.E.g(e,$CLJS.FY),Soa,$CLJS.E.g(e,$CLJS.lK),Toa,$CLJS.E.g(e,$CLJS.dK)],null):null}(),function(){var e=$CLJS.KY.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.q_,e],null)}(),function(){var e=
$CLJS.zz($CLJS.IV,$CLJS.sF)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.r_,$CLJS.Cd($CLJS.rG,e)&&!$CLJS.Cd($CLJS.qG,e)],null):null}(),$CLJS.Cl(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.s_,$CLJS.t_,$CLJS.u_],null))]))};$CLJS.d_=function d_(a){switch(arguments.length){case 0:return d_.o();case 3:return d_.j(arguments[0],arguments[1],arguments[2]);case 4:return d_.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.d_.o=function(){return $CLJS.Se($CLJS.N)};$CLJS.d_.j=function(a,b){return $CLJS.J.g($CLJS.q(a),b)};$CLJS.d_.v=function(a,b,c,d){return $CLJS.zh.v(a,$CLJS.R,b,d)};$CLJS.d_.A=4;$CLJS.w_=new $CLJS.M(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.u_=new $CLJS.M(null,"filter-positions","filter-positions",378749375);$CLJS.x_=new $CLJS.M(null,"direction","direction",-633359395);$CLJS.p_=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);
$CLJS.s_=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.y_=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.q_=new $CLJS.M(null,"selected","selected",574897764);Uoa=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);Toa=new $CLJS.M(null,"is-breakout","is-breakout",-1878069983);
Voa=new $CLJS.r("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.z_=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);$CLJS.A_=new $CLJS.M("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);Soa=new $CLJS.M(null,"is-aggregation","is-aggregation",872502629);
B_=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.C_=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);m_=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);D_=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);h_=new $CLJS.M(null,"named?","named?",-213401078);$CLJS.E_=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);
$CLJS.n_=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.r_=new $CLJS.M(null,"is-temporal-extraction","is-temporal-extraction",-1029608872);$CLJS.F_=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.t_=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);Woa=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.k_=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);
$CLJS.G_=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.j_=new $CLJS.M(null,"long","long",-171452093);$CLJS.H_=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.I_=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);o_=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.J_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Fk,$CLJS.gB],null),$CLJS.Fk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.K_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Fk,$CLJS.gB],null),$CLJS.Fk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.i_=function(){function a(f,k,l,m){var t=$CLJS.zz($CLJS.mD,$CLJS.qD)($CLJS.HD(l));if($CLJS.n(t))return t;try{return $CLJS.J_.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.Th($CLJS.fD("Error calculating display name for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([l])),$CLJS.sU(k)])),new $CLJS.h(null,2,[$CLJS.IE,f,$CLJS.AD,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.Oh):e.call(null,f,k,l,$CLJS.Oh)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.L_=function(){function a(d,e,f){var k=$CLJS.U.h($CLJS.HD(f));if($CLJS.n(k))return k;try{return $CLJS.K_.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.Th($CLJS.fD("Error calculating column name for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([f])),$CLJS.sU(k)])),new $CLJS.h(null,3,[$CLJS.AD,f,$CLJS.IE,d,$CLJS.FK,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.J_.m(null,$CLJS.Oh,function(a,b,c){a=$CLJS.Mz($CLJS.Ex);$CLJS.n($CLJS.Lz("metabase.lib.metadata.calculation",a))&&$CLJS.Kz("metabase.lib.metadata.calculation",a,$CLJS.tV("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.H([$CLJS.xh.l($CLJS.H([c])),Voa,$CLJS.XA(c)])),null);return $CLJS.ud(c)&&$CLJS.z(c)instanceof $CLJS.M?$CLJS.Xg($CLJS.z(c)):$CLJS.xh.l($CLJS.H([c]))});
$CLJS.K_.m(null,$CLJS.Oh,function(a,b,c){return Roa($CLJS.Gz($CLJS.i_.j(a,b,c),/[\(\)]/,""))});$CLJS.M_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Fk,$CLJS.gB],null),$CLJS.Fk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.Xoa=function(){function a(d,e,f){return $CLJS.M_.j(d,e,$CLJS.mh.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.N_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Fk,$CLJS.gB],null),$CLJS.Fk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.O_=function(){function a(d,e,f){var k=$CLJS.HD(f);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.sF),m=$CLJS.oi.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Cd($CLJS.rG,l):l)?$CLJS.Yi:null;if($CLJS.n(l))return l;k=$CLJS.tA.h(k);if($CLJS.n(k))return k;d=$CLJS.N_.j(d,e,f);return $CLJS.Dz(d,$CLJS.bj)?d:$CLJS.bj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.N_.m(null,$CLJS.Oh,function(a,b,c){return $CLJS.OD(c)});$CLJS.N_.m(null,$CLJS.eE,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.O_.j(a,b,c)});$CLJS.N_.m(null,$CLJS.$H,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.OD(c):$CLJS.O_.j(a,b,d)});
$CLJS.P_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Fk,$CLJS.gB],null),$CLJS.Fk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.P_.m(null,$CLJS.Oh,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.fj,$CLJS.NJ,$CLJS.tA,$CLJS.O_.j(a,b,c),$CLJS.U,$CLJS.L_.j(a,b,c),$CLJS.mD,$CLJS.i_.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.Th($CLJS.fD("Error calculating metadata for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([$CLJS.XA(c)])),$CLJS.sU(d)])),new $CLJS.h(null,3,[$CLJS.IE,a,$CLJS.FK,b,$CLJS.AD,c],null),d);}throw e;}});
$CLJS.g_=function(){function a(e,f,k){return $CLJS.P_.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.R_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Fk,$CLJS.gB],null),$CLJS.Fk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.Y(B_,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.k_,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vj],null),new $CLJS.P(null,3,5,$CLJS.Q,[h_,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,B_],null)],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[m_,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.n_,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[o_,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.p_,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.dr,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[D_,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Woa,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Uoa,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),$CLJS.vj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.H_,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.F_,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.q_,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.x_,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.br,$CLJS.jF,$CLJS.cF],null)],null)],null));
$CLJS.l_=function(){function a(d,e,f){return $CLJS.f_($CLJS.mh.g("display-info",["stage-",$CLJS.p.h(e)].join("")),f,function(k){try{return $CLJS.R_.j(d,e,k)}catch(m){if(m instanceof Error){var l=m;throw $CLJS.Th($CLJS.fD("Error calculating display info for {0}: {1}",$CLJS.H([$CLJS.XA(k),$CLJS.sU(l)])),new $CLJS.h(null,3,[$CLJS.IE,d,$CLJS.FK,e,$CLJS.AD,k],null),l);}throw m;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.R_.m(null,$CLJS.Oh,function(a,b,c){return v_(a,b,c)});$CLJS.R_.m(null,$CLJS.TJ,function(a,b,c){return $CLJS.wk.l($CLJS.H([v_(a,b,c),new $CLJS.h(null,1,[D_,$CLJS.E.g($CLJS.WZ(a),$CLJS.Hi.h(c))],null)]))});
$CLJS.S_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Fk,$CLJS.gB],null),$CLJS.Fk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();$CLJS.S_.m(null,$CLJS.wA,function(){return $CLJS.xf});
$CLJS.T_=function(){function a(f,k,l,m){m=$CLJS.wk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.E_,$CLJS.HV()],null),m]));return $CLJS.S_.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.EV(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.U_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Fk,$CLJS.gB],null),$CLJS.Fk,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();$CLJS.U_.m(null,$CLJS.wA,function(){return $CLJS.xf});
$CLJS.U_.m(null,$CLJS.Oh,function(a,b,c,d){return $CLJS.S_.v(a,b,c,d)});
$CLJS.V_=function(){function a(f,k,l,m){m=$CLJS.wk.l($CLJS.H([$CLJS.wk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.E_,$CLJS.HV()],null),new $CLJS.h(null,4,[$CLJS.C_,!0,$CLJS.I_,!0,$CLJS.y_,!0,$CLJS.G_,!0],null)])),m]));return $CLJS.U_.v(f,k,l,m)}function b(f,k,l){return $CLJS.n(function(){var m=$CLJS.sd(l);if(m){m=$CLJS.fj.h(l);var t=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.aV,null,$CLJS.UU,null],null),null);return t.h?t.h(m):t.call(null,m)}return m}())?$CLJS.f_($CLJS.mh.h([$CLJS.p.h(k),"__visible-columns-no-opts"].join("")),
f,function(){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}):e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.EV(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
module.exports={display_name:$CLJS.i_};